import React, { useState, useEffect } from "react";
import { ReactComponent as Eth } from "../../Assets/eth.svg";
// import cardImg from "../../Assets/mobileLogo.png";
import { ethers } from "ethers";
import { parseEther, formatEther } from "ethers/lib/utils.js";
import { toast } from "react-toastify";
import oddtoken from "../../Assets/token.jpg";
import {
  useBalance,
  useContractReads,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { presaleContract } from "../Utils/constant";
import { NotifyError, NotifyPending, NotifySuccess } from "../Notify/Notify";

const PublicCard = ({ address }) => {
  const [contributionAmount, setContributionAmount] = useState("0");
  const [contributionIncrement, setContributionIncrement] = useState(0);
  const [maxContributed, setMaxContributed] = useState(false);
  let toastId = React.useRef(null);

  // ====================== 💰 PRICING 💰 =======================
  useEffect(() => {
    if (contributionIncrement === 1) {
      setContributionAmount("0.1");
    } else if (contributionIncrement === 2) {
      setContributionAmount("0.2");
    } else if (contributionIncrement === 3) {
      setContributionAmount("0.5");
    } else if (contributionIncrement === 4) {
      setContributionAmount("0.7");
    } else if (contributionIncrement === 5) {
      setContributionAmount("0.9");
    } else if (contributionIncrement === 6) {
      setContributionAmount("1");
    } else if (contributionIncrement === 7) {
      setContributionAmount("1.2");
    } else if (contributionIncrement === 8) {
      setContributionAmount("1.5");
    } else if (contributionIncrement === 9) {
      setContributionAmount("2");
    }
  }, [contributionIncrement]);

  // ================== 📝 CONTRACT WRITES 📝 ===================
  // Public Phase Contract Writes
  const { config } = usePrepareContractWrite({
    ...presaleContract,
    functionName: "contributeToPublicPresale",
    overrides: { value: ethers.utils.parseEther(contributionAmount || "0") },
  });

  const {
    write: Contribute,
    data: contributionData,
    isSuccess: isContributionStarted,
  } = useContractWrite(config);

  const { isSuccess: txSuccess } = useWaitForTransaction({
    hash: contributionData?.hash,
  });

  // ================== 📚 CONTRACT READS 📚 ===================
  // Whitelist Phase Contract Reads
  const { data, refetch } = useContractReads({
    contracts: [
      {
        ...presaleContract,
        functionName: "getContributedByAddress",
        args: [address],
      },
      {
        ...presaleContract,
        functionName: "getTotalContribution",
      },
    ],
    // watch: true,
    onSettled(data) {
      if (formatEther(data?.[0]?.toString()) >= 2) {
        setMaxContributed(true);
      } else {
        setMaxContributed(false);
      }
      // TODO: here is the error to fix,
    },
  });
  const { data: balanceData } = useBalance({
    address: address,
    watch: true,
  });

  // ========================== ➖ DECREMENT / INCREMENT ➕ ===========================
  const handleDecrement = () => {
    if (contributionIncrement <= 1) return;
    setContributionIncrement(contributionIncrement - 1);
  };

  const handleIncrement = () => {
    if (contributionIncrement < 9) {
      setContributionIncrement(contributionIncrement + 1);
    }
  };

  // =============== 🔄 PROCESSING & HANDLING 🛠 ================
  useEffect(() => {
    if (isContributionStarted) {
      pendingContribution();
    }
  }, [isContributionStarted]);

  useEffect(() => {
    if (txSuccess) {
      const fetchData = async () => {
        await refetch();
        successfullyContributed();
        setContributionAmount("0");
        setContributionIncrement(0);
      };
      fetchData();
    }
  }, [txSuccess]);

  // ================ 🍞 TOAST / CHECKOUT 🛒 =================
  const checkOutPublic = () => {
    if (formatEther(data?.[1]?.toString()) < 130) {
      if (contributionAmount == 0 || contributionAmount == "null") {
        NotifyError("Insufficient Contribution !");
      } else {
        if (
          parseEther(contributionAmount)
            .add(data?.[0] ?? "0")
            .gt(parseEther("2"))
        ) {
          NotifyError("Max Contribution is 2 ETH !");
        } else if (balanceData.formatted < contributionAmount) {
          NotifyError("Insufficient Funds !");
        } else {
          Contribute?.();
        }
      }
    } else {
      NotifyError("Presale Hard Cap Filled !");
    }
  };

  const pendingContribution = () => {
    toastId.current = NotifyPending("Processing Contribution");
  };

  const successfullyContributed = () => {
    toast.dismiss(toastId.current);
    toastId.current = NotifySuccess("Contribution Successful !");
  };

  return (
    <div className="w-[300px]  tab:w-[375px] tab:h-[600px] bg-[#4c33e9] bg-opacity-50 backdrop-blur-lg rounded-lg flex flex-col items-center border-[1px] border-[#2a2169] fadeBox  font-bold text-[#ddd]">
      <div className="text-[20px] tab:text-[25px] text-center  flex justify-center gap-2 items-center">
        Contributions: {formatEther(data?.[1]?.toString() ?? "0")} / 130{" "}
        <Eth className="h-8 tab:h-12" />
      </div>
      <img
        src={oddtoken}
        alt="oddplanet-nfts"
        className="tab:h-[350px] w-[93%] mt-2 rounded-lg"
      />
      <div className="text-[60px] tab:text-[80px] w-full flex justify-between items-center  px-4 ">
        <button
          onClick={handleDecrement}
          disabled={maxContributed}
          className=" hover:scale-105 duration-300 w-[50px] h-[50px] tab:w-[70px]  tab:h-[70px]  bg-[#1D6A7C] flex justify-center items-center rounded-lg  border border-[#ddd] mt-[12px]"
        >
          <p className="pb-2 tab:pb-4 pl-0.5  tab:pl-1">-</p>
        </button>

        <p className="w-[90px] flex justify-center font-normal">
          {contributionAmount}
        </p>

        <button
          onClick={handleIncrement}
          disabled={maxContributed}
          className=" hover:scale-105 duration-300 w-[50px] h-[50px] tab:w-[70px]  tab:h-[70px]  bg-[#1D6A7C] flex justify-center items-center rounded-lg  border border-[#ddd] mt-[12px]"
        >
          <p className="pb-2 tab:pb-4 pl-0.5">+</p>
        </button>
      </div>

      <div className="flex justify-center items-center w-full px-4 pb-2">
        <button
          onClick={maxContributed ? null : checkOutPublic}
          className={
            maxContributed
              ? "contributeBtnDisabled"
              : "w-full h-[55px] tab:h-[60px] rounded-lg bg-[#1D6A7C]  border border-[#ddd] text-[30px] flex justify-center items-center contributeHoverUp  z-50"
          }
        >
          {maxContributed ? "MAX CONTRIBUTED" : "CONTRIBUTE"}
        </button>
      </div>
    </div>
  );
};

export default PublicCard;
