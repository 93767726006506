import abi from "./contract-abi.json";

// =========================== ABI ===============================
export const contractABI = abi;

// ====================== CONTRACT ADDRESS =======================
export const contractAddress = "0xad060c5004a63d8471e917568e4cc5208ef7133a";

// ========================== CONFIGS =========================== //
export const presaleContract = {
  address: contractAddress,
  abi: contractABI,
};
