import { useState, useEffect } from "react";
import Header from "./Components/Header/Header";
import Coins1 from "./Assets/tokenCoins.png";
import Coins2 from "./Assets/tokenCoins2.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import PublicCard from "./Components/ContributionCards/PublicCard";
import WhitelistCard from "./Components/ContributionCards/WhitelistCard";
import { MerkleProof, VerifyWallet } from "./Components/Merkle/MerkleProof";
import Claim from "./Components/Claim/Claim";

function App() {
  const [proof, setProof] = useState();
  const [isValid, setValid] = useState();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (isConnected) {
      // MerkleProof(); //JUST TO GET ROOT
      if (VerifyWallet(address)) {
        setValid(1);
        setProof(MerkleProof(address));
        // console.log("proofBABY:", proof);
      } else {
        setValid(2);
      }
    }
  }, [isConnected, address]);

  return (
    <div className="h-screen font-Mulish bg-presaleBg bg-cover bg-center flex justify-center items-center">
      <Header />
      <img
        src={Coins1}
        alt="$ODD"
        className="fixed bottom-0 left-0 tab:block hidden tab:h-[40%] lap:h-[50%]"
      />
      <img
        src={Coins2}
        alt="$ODD"
        className="fixed bottom-0 right-0 tab:block hidden tab:h-[25%] lap:h-[35%]"
      />
      {isConnected ? <></> : <ConnectButton />}

      {/* WHITELIST PHASE */}
      {/* {isConnected ? (
        <WhitelistCard isValid={isValid} address={address} proof={proof} />
      ) : (
        <></>
      )} */}

      {/* PUBLIC PHASE */}
      {isConnected ? <PublicCard address={address} /> : <></>}

      {/* CLAIM PHASE */}
      {/* {isConnected ? <Claim address={address} /> : <></>} */}

      <ToastContainer />
    </div>
  );
}

export default App;
