import React from "react";
import logo from "../../Assets/logo.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import mobileLogo from "../../Assets/mobileLogo.png";

const Header = () => {
  const { isConnected } = useAccount();

  return (
    <div className="fixed z-10 top-6 flex justify-between px-10 items-center w-full">
      <img
        src={logo}
        alt="oddplanet-presale"
        className="h-14 tab:block hidden"
      />
      <img
        src={mobileLogo}
        alt="oddplanet-presale"
        className="h-14 tab:hidden"
      />
      {isConnected ? (
        <ConnectButton
          accountStatus={{
            smallScreen: "avatar",
            largeScreen: "full",
          }}
          chainStatus={"none"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Header;
